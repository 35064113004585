import { Masonry } from '@mui/lab';
import { Typography, Rating, Box } from '@mui/material';
import { Types } from '@scorenco/core';

import { Wrapper } from '../../primitives';
import { Card } from '../../primitives/Card';

type ReviewsSliceProps = {
  slice: Types.ComponentSlicesReviews;
};

export const ReviewsSlice = ({ slice }: ReviewsSliceProps) => {
  if (!slice.reviews?.length) {
    return null;
  }

  return (
    <Wrapper marginY={5}>
      <Masonry columns={{ xs: 1, sm: 3 }} spacing={3}>
        {slice.reviews.map((review, index) => (
          <Card key={index} alignItems="baseline" disabled={true}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                gap: 1,
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
                {review?.nom}
              </Typography>
              <Rating value={review?.note} precision={0.5} readOnly />
              <Typography variant="body2">{review?.avis}</Typography>
            </Box>
          </Card>
        ))}
      </Masonry>
    </Wrapper>
  );
};
